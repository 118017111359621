.column-3 {
  width: calc((100% / 3) - 18px);
  img {
    width: 100%;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0.5rem 0;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px dotted var(--border-color);
    margin: 0.5rem 0;
    padding: 0;
  }
  p {
    margin: 0;
  }
  i.fitted.icon {
    margin: 0 0.5rem 0 0 !important;
  }
  &:hover {
    cursor: pointer;
    h3 {
      color: var(--primary-color);
    }
  }
  @media only screen and (max-width: 991px) {
    width: calc((100% / 2) - 12px) !important;
  }
  @media only screen and (max-width: 767px) {
    width: 100% !important;
  }
}

.article-wrap {
  .tags {
    width: 240px;
    margin: 0 0 0 1.5rem;
    padding: 0 0 0 1.5rem;
    border-left: 1px dotted var(--border-color);
    section {
      margin-bottom: 3rem;
      .related {
        a {
          display: block;
          margin-bottom: 1rem;
          font-weight: 500;
        }
      }
    }
  }
  .article {
    h1 {
      font-size: 2.5rem;
    }
    img {
      width: 100%;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.125rem;
      line-height: 2;
      &:nth-of-type(1) {
        &:first-letter {
          font-size: 4.125rem;
          line-height: 4.125rem;
          font-weight: 400;
          text-transform: uppercase;
          float: left;
          padding-top: 4px;
          padding-right: 0.25rem;
          margin-bottom: -4px;
        }
      }
    }
    .article-figure {
      float: right;
      border: thin solid silver;
      margin: 0.25rem 0 1rem 1rem;
      padding: 0.25rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      > * {
        display: block;
        margin: 0.5rem;
        color: #323231;
        width: 2rem;
        height: 2rem;
      }
      .share {
        font-size: 0.67rem;
      }
    }
  }
}

.section-head {
  padding-left: 0.5rem;
  border-left: 1rem solid var(--primary-color);
  margin-bottom: 1.5rem;
  line-height: 0.875;
  &.purple {
    border-left: 1rem solid var(--secondary-color);
  }
  &.yellow {
    border-left: 1rem solid #ceb80e;
  }
  &.red {
    border-left: 1rem solid #db4813;
  }
}
