/*
Influenced heavily by: https://codepen.io/ageris/pen/dGbQve
*/

$material-form-label: #000000;
$material-form-label-focus: #3f51b5;
$material-form-line: #000000;
$material-form-line-focus: $material-form-label-focus;
$material-form-input-text: $material-form-label;
$material-form-input-text-focus: $material-form-label-focus;
$material-form-field-invalid-foreground: #d50000;

$material-dropdown-hoverBackground: #e8eaf6;
$material-dropdown-selectedBackground: #9fa8da;
$material-labelOffset: 25px;
$material-textInputPaddingTopBottom: 10px;

/*
A dropdown menu that covers the content
Note: MUST be placed inside a relative position element (works with .material-form-field)
Expected html:
<ul class="material-dropdown">
<li>Item 1</li>
<li>Item 2</li>
<li>Item 3</li>
...
</ul>
*/
.material-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background: #ffffff;
  color: #000000;
  z-index: 1000;

  transition: 0.2s ease-in height, step-end box-shadow 0.2s;
  -moz-transition: 0.2s ease-in height, step-end box-shadow 0.2s;
  -webkit-transition: 0.2s ease-in height, step-end box-shadow 0.2s;

  &.material-dropdown-open {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  li {
    padding: 10px;
    cursor: default;
    user-select: none;
    &:hover {
      background-color: $material-dropdown-hoverBackground;
    }
    &.material-dropdown-selected {
      background-color: $material-dropdown-selectedBackground;
    }
  }
}

/*
A material design form field
Expects this html (note the required attribute on the input):
<div class="material-form-field">
<input type="text" required/>
<label class="material-form-field-label">Form field title</label>
</div>
*/
.material-form-field {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  position: relative;
  display: block;
  color: $material-form-label;
  padding: $material-labelOffset 0 10px 0;

  &::after {
    content: "\00A0";
    display: block;
    color: $material-form-field-invalid-foreground;
    font-size: 12px;
    padding-top: 5px;
  }

  .material-form-field-label {
    position: absolute;
    display: block;
    top: $material-labelOffset + $material-textInputPaddingTopBottom;
    left: 0px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
    cursor: text;
  }

  input {
    padding: $material-textInputPaddingTopBottom 0
      $material-textInputPaddingTopBottom 0;
    display: block;
    width: 100%;
    border: none;
    font-size: 14px;
    color: $material-form-input-text;
    border-bottom: 1px solid $material-form-line;
    outline: none;

    &:invalid {
      outline: none;
      box-shadow: none;
    }
    &:valid,
    &:focus {
      & ~ .material-form-field-label {
        top: $material-labelOffset -
          ($material-textInputPaddingTopBottom + 5);
        font-size: 12px;
      }
    }
    &:focus {
      outline: none;
      color: $material-form-input-text-focus;
      padding-bottom: $material-textInputPaddingTopBottom - 2;
      border-bottom-width: 3px;
      border-bottom-color: $material-form-line-focus;

      & ~ .material-form-field-label {
        color: $material-form-label-focus;
      }

      & ~ .material-dropdown {
        /*
        If you want to animate this you will need to use some js to calculate it
        Optionally, you can simply display:block/none 
        */
        height: auto;
        max-height: 228px;
        overflow-y: scroll;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
        transition: 0.2s ease-out height, step-start box-shadow 0.2s;
        -moz-transition: 0.2s ease-out height,
          step-start box-shadow 0.2s;
        -webkit-transition: 0.2s ease-out height,
          step-start box-shadow 0.2s;
      }
    }
  }

  &.material-form-field-invalid {
    color: $material-form-field-invalid-foreground;
    &::after {
      content: attr(data);
    }
    input:focus ~ .material-form-field-label {
      color: $material-form-field-invalid-foreground;
    }
  }
}
