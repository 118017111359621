.main-footer {
  z-index: 1;
  position: relative;
  padding: 1rem 0;
  margin-top: 1em;
  background: var(--primary-color-darker);
  color: rgba(255, 255, 255, 0.85);
  .flex-box {
    justify-content: space-between;
  }
  h6 {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
  a,
  span.link {
    font-weight: 400;
    display: block;
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .legal {
    margin: 1rem 0 0 0;
    padding-top: 1rem;
    border-top: 1px solid var(--primary-color);
    font-size: 12px;
    a {
      display: inline-block;
    }
  }
  @media only screen and (max-width: 767px) {
    padding-top: 0;
    h6 {
      margin-top: 1rem;
    }
  }
}
