/* main container */
.main {
  margin-top: 6rem;
  padding: 0 0 0 9rem;
  > .content {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 234px);
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    border-left: 1px dotted var(--border-color);
    @media only screen and (max-width: 767px) {
      padding-left: 0;
      border-left: none;
    }
  }

  &.checkout,
  &.dashboard,
  &.admin,
  &.adminhome,
  &.profile,
  &.jobs,
  &.resources,
  &.spedxchange-reports,
  &.user {
    padding: 0 0 0 0;
    > .content {
      padding-left: 0.5rem;
      border-left: none;
      @media only screen and (max-width: 767px) {
        padding-left: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
}
