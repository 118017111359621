.ui.form.search-form {
  transition: top 0.25s ease-out;
  position: fixed;
  z-index: 2;
  top: -4px;
  background: var(--primary-color-dark);
  justify-content: space-around;
  padding: 0.625rem 0;
  border-top: 1px solid #008458;
  input,
  button,
  i {
    height: 25px;
  }
  .ui.action.input {
    > .button {
      padding: 1px 0.5rem 0 0.5rem;
      font-weight: 500;
      background: rgba(255, 255, 255, 0.25);
      color: rgba(255, 255, 255, 0.9);
      &:hover {
        background: rgba(255, 255, 255, 0.13);
        color: rgba(255, 255, 255, 1);
      }
    }
    &::placeholder {
      color: red !important;
      opacity: 1;
    }
  }
  &.open {
    top: 40px;
  }
}
