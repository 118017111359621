.publish-panel {
  width: 240px !important;
}

.admin-nav {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
}

.preview-wrap {
  max-width: 540px;
}

.apply-section {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.bookmark-section {
  padding-top: 0.75rem;
}

.admin-content {
  min-height: 165.25px;

  .admin-form {
    .ui.mini.labeled.input {
      width: 100%;
      margin-bottom: 0.25rem;
      .ui.label {
        width: 60px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
