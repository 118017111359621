.main.resources {
    h1 {
        font-size: 3rem;
        text-align: center;
        color: var(--secondary-color);
        @media only screen and (max-width: 767px) {
            font-size: 1.5rem;
        }
    }
    .ovo {
        color: var(--secondary-color);
    }
    section {
        width: 100%;
        margin-bottom: 3rem;
        ul {
            margin-bottom: 0;
            li {
                &:last-of-type {
                    margin-bottom: 0.5rem;
                }
            }
        }
        .video-description {
            width: 172px;
            h5 {
                color: var(--secondary-color);
                font-size: 1.2rem;
                font-weight: 700;
                margin-bottom: 0.25rem;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                br {
                    display: none;
                }
            }
        }
        &.head {
            .ui.primary.button {
                min-width: 250px;
            }
        }
        @media only screen and (max-width: 767px) {
            .spacer {
                height: 1rem;
            }
        }
        &.photo-section {
            h1 {
                color: var(--secondary-color);
                font-size: 2.5rem;
                line-height: 1.25;
                font-weight: 300;
            }
            p {
                font-size: 1.2rem;
                font-weight: 400;
            }
            .call-out-box {
                color: var(--secondary-color);
                background-color: #fff;
                padding: 1rem 1.5rem;
                border-radius: 0.5rem;
                box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
                li {
                    font-size: 1.2rem;
                }
            }
            .section-img {
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                &.sped-professionals {
                    background-image: url(./sped-professionals.jpg);
                }
                &.sped-student {
                    background-image: url(./sped-student.jpg);
                }
                &.quote {
                    div {
                        background: var(--primary-color);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }
                    h5 {
                        padding: 1.2rem;
                        font-weight: normal;
                        display: block;
                        font-size: 2rem;
                        color: #fff;
                        text-align: center;
                    }
                }
                @media only screen and (max-width: 767px) {
                    min-height: 480px;
                    &.quote {
                        min-height: 20px;
                        h5 {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .eight.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
            padding: 0 0 1rem 0 !important;
        }
    }
}

.info-form {
    .ovo {
        color: #fff !important;
    }
    label {
        font-size: 0.8rem !important;
    }
    .spacer.middle {
        width: 2rem;
    }
    .error.field {
        label {
            color: pink !important;
            font-weight: 700 !important;
        }
        input {
            background: pink !important;
        }
    }
    .tell-us {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    @media only screen and (max-width: 767px) {
        .spacer.hidden-sm {
            display: none;
        }
        .tell-us {
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
        }
    }
}
