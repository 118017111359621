.jobs-search-form {
  margin-bottom: 0;
  .term {
    position: relative;
    font-size: 1.25rem;
    padding-right: 0.5rem;
    .field {
      margin-bottom: 0;
    }
    strong {
      content: "What";
      position: absolute;
      top: 0.633rem;
      left: 0.75rem;
      height: 20px;
      color: #111;
      font-weight: 700;
    }
    input {
      position: relative;
      padding-left: 68px !important;
    }
    @media only screen and (max-width: 766px) {
      padding-right: 0;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    position: relative;
    font-size: 1.25rem;
    padding-right: 0.25rem;
    .field {
      margin-bottom: 0;
    }
    strong {
      content: "Where";
      position: absolute;
      top: 0.633rem;
      left: 0.75rem;
      height: 20px;
      color: #111;
      font-weight: 700;
    }
    input {
      position: relative;
      padding-left: 77px !important;
    }
    @media only screen and (max-width: 766px) {
      padding: 0;
      margin-bottom: 0.5rem;
    }
  }
  .submit {
    padding-left: 0.3rem;
    margin-top: 1px;
    button {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 766px) {
      padding-left: 0;
      text-align: center;
    }
  }
}

.job-search-filters {
  padding: 1rem 0 0.625rem 0;
  span {
    display: inline-block;
    padding-right: 1.5rem;
    @media only screen and (max-width: 766px) {
      display: block;
      padding-top: 0.5rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: 766px) {
    padding: 0.25rem 0 0.25rem 0;
  }
}

.sticky-job {
  position: sticky;
  top: 60px;
}

.jobs {
  .info {
    margin-bottom: 1rem;
    .ui.label {
      margin-top: 0.5rem;
    }
  }
}

.job-list {
  max-width: 542px;
  .job-list-item {
    transition: all 1s ease;
    padding-bottom: 0.5rem;
    h3 {
      font-size: 1.2rem;
      margin: 0.5rem 0;
    }
    p {
      margin-bottom: 0.5rem;
    }
    &.active {
      padding: 1rem;
      background: rgba(255, 255, 255, 1);
      border-radius: 0.25rem;
      hr {
        display: none;
      }
    }
  }
}
