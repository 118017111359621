.edit-icon {
  color: var(--primary-color);
  opacity: 0.3;
  height: 16px;
  line-height: 1;
  padding: 0;
  margin: -6px 0 0 0.5rem;
  &:hover {
    color: var(--primary-color);
    opacity: 1 !important;
  }
}
.edit-trigger {
  cursor: pointer;
  position: absolute;
  width: 42px;
  height: 34px;
  top: 0;
  right: 0;
}
.remove-trigger {
  cursor: pointer;
  position: absolute;
  width: 42px;
  height: 34px;
  top: 34px;
  right: 0;
}
.editable {
  position: relative;
  margin-bottom: 24px;
  padding-right: 40px;
  transition-delay: 0.25s;
  border: 0px solid var(--primary-color);
  border-radius: 4px;
  transition: all 0.35s linear 0.25s;
  &.enabled {
    &:before {
      transition: all 0.35s linear 0.25s;
      color: var(--primary-color);
      position: absolute;
      top: 9px;
      right: 12px;
      opacity: 0.3;
      font: normal normal normal 16px/1 FontAwesome;
      font-size: 18px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: '\f040';
    }
  }
}
.editable.active {
  background: #fff;
  border: 1px solid var(--primary-color);
  margin-bottom: 10px;
  padding: 6px 9px;
  &.enabled {
    &:before {
      opacity: 1;
      top: 8px;
      right: 11px;
    }
  }
}
.editable.removable {
  &:after {
    pointer-events: none;
    transition: all 0.35s linear 0.25s;
    color: var(--primary-color);
    position: absolute;
    top: 39px;
    right: 14px;
    opacity: 0.3;
    font: normal normal normal 16px/1 FontAwesome;
    font-size: 18px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\f00d';
  }
}
.editable.removable.active {
  &:after {
    opacity: 1;
    top: 38px;
    right: 13px;
  }
}
