/*home page styles*/
.home-section {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ui.container {
    h2 {
      font-size: 3.3rem;
      font-weight: 400;
      line-height: 1.2;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.5;
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      h2 {
        font-size: 3rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      h2 {
        font-size: 2.75rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
    @media only screen and (max-width: 767px) {
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  &.hero {
    min-height: 100vh;
    margin-top: 40px;
    // background-image: url("/assets/img/bg-home.png"), linear-gradient(135deg, rgb(103, 28, 142) 0%, rgb(0, 102, 70) 96%) !important;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    .ui.container {
      text-align: center;
      color: #fff;
      h5 {
        margin-bottom: 1.5rem;
        @media only screen and (max-width: 680px) {
          font-size: 1rem;
        }
      }
      h2 {
        @media only screen and (max-width: 680px) {
          font-size: 2rem;
        }
      }
      .actions {
        font-size: 1rem;
        max-width: 480px;
        margin: 1.5rem auto 0 auto;
        color: rgba(255, 255, 255, 0.8);
      }
      .call-out {
        width: 400px;
        background-color: rgba(255, 255, 255, 0.45);
        padding: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 6px;
      }
      .ui.buttons.options {
        h5 {
          font-weight: bold;
          margin-bottom: 1rem;
        }
        .or {
          margin-top: 5.75rem !important;
        }
        .options-info {
          position: relative;
          text-align: left;
          width: 290px;
          margin: 0 auto;
          padding-bottom: 24px;
        }
        .start {
          position: absolute;
          left: 85px;
          bottom: -34px;
          width: 120px;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.25);
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .ui.buttons.options {
        flex-direction: column;
        align-items: center;
        h5 {
          font-size: 1.25rem;
        }
        .or {
          display: none;
          margin-top: -0.75rem !important;
        }
        .call-out:last-of-type {
          margin-top: 2.5rem !important;
        }
      }
    }
    &.user {
      min-height: 60vh;
    }
  }
  &.news {
    // background-image: url("/assets/img/speducator-reading.jpg");
    background-position: center;
    background-size: cover;
    .ui.container {
      color: #fff;
      > * {
        max-width: 24rem;
      }
      ul {
        padding-left: 1rem;
        max-width: 17rem;
        li {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  &.professional {
    // background-image: url("/assets/img/speducator-reading.jpg");
    background-position: center;
    background-size: cover;
    .ui.container {
      color: #111;
      p {
        font-size: 1.125rem;
      }
      .narrow {
        max-width: 24rem;
        margin-bottom: 1.5rem;
      }
      .wide {
        max-width: 35rem;
        margin-bottom: 1.5rem;
      }

      h4 {
        font-weight: 600;
        margin-bottom: 0;
        color: var(--primary-color);
      }
      @media only screen and (max-width: 767px) {
        .wide {
          max-width: 70%;
        }
      }
      @media only screen and (max-width: 600px) {
        p {
          font-size: 1rem;
        }
        .hidden-sm {
          display: none;
        }
        .narrow {
          max-width: 100%;
        }
        .wide {
          max-width: 80%;
        }
      }
    }
  }
  &.shortage {
    background-color: #008a3b;
    .ui.container {
      color: #fff;
      text-align: center;
      ul {
        padding-left: 1rem;
        max-width: 360px;
        text-align: left;
        li {
          padding-bottom: 0.5rem;
        }
      }
      p {
        max-width: 570px;
      }
      > * {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  &.resources {
    background-color: #e3eff7;
    .ui.container {
      text-align: center;
      .callout {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .grow {
          text-align: center;
          margin-bottom: 1.5rem;
          > * {
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
          }
          i {
            font-size: 3rem;
            display: block;
            margin-bottom: 15px;
            color: #008a3b;
          }
          h3 {
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
            margin-top: 0;
          }
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 2.5rem 0;
  }
}

.user-message {
  background-color: rgba(0, 102, 70, 0.15);
  padding-bottom: 1.5rem;
  @media only screen and (max-width: 767px) {
    .column-3 {
      max-width: 320px !important;
    }
  }
}

.footer-wrap {
  > .main-footer {
    margin-top: 0 !important;
  }
}
