.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 67%;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.profile-wrap {
  section {
    max-width: 420px;
    margin: 0 auto 1.5rem auto;
    .section-header {
      position: relative;
      overflow: hidden;
      h4 {
        font-size: 1.2rem;
        margin-bottom: 0.25rem;
        text-align: left;
        color: #0d0f4a;
      }
      span {
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 6px;
      }
    }
    .section-body {
      background: #fff;
      border-radius: 5px;
      border: 1px solid #e4e1e1;
      .profile-about {
        padding: 15px;
        .avatar {
          width: 72px;
          height: 72px;
          overflow: hidden;
          border-radius: 50%;
          background-color: #ddd;
          margin: 0 auto;
          margin-bottom: 15px;
        }

        .profile-name {
          font-size: 1.25rem;
          margin-bottom: 5px;
        }
        .profile-headline {
          margin-bottom: 5px;
        }
        p {
          margin: 0;
          color: rgb(151, 151, 151);
        }
      }
      .section-ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          padding: 15px;
          &:not(:last-child) {
            border-bottom: 1px solid #e4e1e1;
          }
          .add-btn {
            text-decoration: none;
            display: flex;
            align-items: center;
            color: #888888;
            transition: 0.3s;
            padding: 15px;
            margin: -15px;
            transition: 0.3s;
            &:hover {
              background-color: #ddd;
              color: #000;
              i {
                background-color: #ffffff;
              }
            }
            i {
              display: flex;
              margin-right: 10px;
              height: 30px;
              width: 30px;
              background-color: #cdd4d7;
              color: #2e3e4b;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              transition: 0.3s;
            }
          }
          .switch {
            margin-right: 10px;
          }
        }
        li.details {
          h6 {
            font-weight: 600;
            color: #0d0f4a;
            margin: 0;
          }
          .edit-btn {
            position: absolute;
            right: 12px;
            top: 16px;
            cursor: pointer;
            color: #6da8d1;
          }
          .remove-btn {
            position: absolute;
            right: 39px;
            top: 16px;
            cursor: pointer;
            color: #b45c4c;
          }
          position: relative;
          padding-right: 50px;
          color: #888888;
          p {
            margin: 0;
          }
        }
      }
    }
  }

  .w-100 {
    width: 100% !important;
  }

  .d-flex {
    display: flex !important;
  }

  .m-0 {
    margin: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  h6 {
    font-size: 1rem;
    font-weight: bolder;
  }

  .section-content {
    margin: 0.25rem 0 2rem;
    background-color: #fff;
    border: 1px solid #e0e2e3;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 1rem;
  }
}

.avatar_wrap {
  position: relative;
  margin: 0 auto 5px auto;
  width: 72px;
  height: 72px;
  .avatar {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #eee;
    margin: 0;
    padding: 0;
    border: none;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    img {
      display: block;
    }
  }
  .icon {
    background: #fff;
    position: absolute;
    bottom: -3px;
    right: -9px;
    &:hover {
      color: var(--blue);
      background: #eee;
    }
  }
}
