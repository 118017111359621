.main.spedxchange-reports {
    margin-top: 4.5rem;
}

.fixed-scroll-table {
    overflow-y: auto;
    height: calc(100vh - 234px);
    table {
        border-collapse: collapse;
        width: 100%;
        thead th {
            position: sticky;
            top: 0;
        }
    }
}
