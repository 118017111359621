$gray-500: #495057;
$green: #006646;

.admin-dashboard {
    section {
        margin-bottom: 1.5rem;

        h1 {
            margin: 0 0 0.125rem 0;
            border-top: 6px solid $gray-500;
            color: $green;
            font-weight: 700;
            .slash {
                font-size: 1.8rem;
                vertical-align: top;
            }
        }
        h6 {
            padding-top: 0.5rem;
            border-top: 6px solid $gray-500;
            color: $green;
            font-weight: 700;
        }
        label {
            display: block;
            color: $gray-500;
            margin: -0.25rem 0 0.25rem 0;
        }
        > div {
            border-top: 1px dotted $gray-500;
            padding: 0.25rem 0;
            strong {
                font-weight: 700;
            }
            span {
                display: inline-block;
                padding-left: 0.5rem;
                color: $gray-500;
            }
            &:last-of-type {
                border-bottom: 1px dotted $gray-500;
            }
        }
    }
}
