.nav-mobile {
  position: fixed;
  z-index: 1;
  transition: all 0.4s ease-out;
  margin: 0;
  padding: 0;
  top: -100%;
  width: 100%;
  height: calc(100% - 39px);
  background: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  ul {
    transition: all 0.4s ease-out;
    list-style-type: none;
    background-color: var(--primary-clear);
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    margin: 0;
    padding: 0;
    li {
      transition: none !important;
      border-top: 1px solid rgba(255, 255, 255, 0.18);
      span {
        display: block;
        color: #fff;
        padding: 0.75rem 1.5rem;
        text-decoration: none;
        &.active {
          background-color: #4caf50;
          color: white;
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100&;
        background-color: var(--primary-color);
        li span {
          padding-left: 3rem;
        }
      }
      &.nav-spacer {
        height: 1px;
        border-top: none;
        background: rgba(255, 255, 255, 0.36);
      }
    }
  }
  &.open {
    top: 39px;
    ul {
      background-color: var(--primary-color-dark);
      li {
        span {
          &:hover:not(.active) {
            background-color: var(--primary-color-darker);
            color: white;
          }
        }
      }
    }
  }
}
