// ask-question-modal
.ui.modal.ask-question-modal {
  .description {
    font-weight: 500;
  }
  .spacer {
    display: inline-block;
    margin: 0 auto;
  }
  .ui.button {
    padding: 0.625rem 0.8rem 0.5rem 0.75rem;
  }
  .header {
    padding: 1rem 1.5rem;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }
}

// question tabs
.question-tabs {
  .ui.secondary.menu {
    justify-content: center;
    margin-bottom: 3rem;
  }
  .ui.segment {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  .ui.radio.checkbox {
    padding-bottom: 0.5rem;
  }
  .columns {
    @media only screen and (min-width: 997px) {
      column-count: 3;
    }
    @media only screen and (max-width: 996px) {
      column-count: 2;
    }
  }
}

.question-hints {
  width: 200px;
  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  ul {
    padding: 0 0 0 1rem;
    margin: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    order: 1;
  }
}

.question-form {
  &.tab-2 {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px dotted var(--border-color);
  }
  .columns {
    @media only screen and (min-width: 997px) {
      column-count: 3;
    }
    @media only screen and (max-width: 996px) {
      column-count: 2;
    }
  }
  .ui.labeled.icon.button,
  .ui.labeled.icon.buttons {
    position: relative;
    padding-left: 3em !important;
    padding-right: 1em !important;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 1.5rem;
    order: 2;
    &.tab-2 {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
}
