#HomeIntroSection {
    padding: 3rem 0;
    color: #fff;
    margin-top: 40px;
    background: #632991 url(./bg-intro.jpg) no-repeat right bottom;
    background-size: auto 120%;
    h1 {
        max-width: 420px;
        font-size: 2.5rem;
        line-height: 1.25;
        font-weight: 400;
    }
    p {
        max-width: 420px;
        font-size: 1.2rem;
        font-weight: 400;
    }
    .ui.button {
        min-width: 260px;
        text-align: left;
    }
    @media only screen and (max-width: 1199px) {
        background-size: auto 112%;
    }
    @media only screen and (max-width: 1105px) {
        background-size: auto 100%;
    }
    @media only screen and (max-width: 990px) {
        background: #632991 url(./bg-intro-sm.jpg) no-repeat right
            bottom;
        background-size: auto 120%;
    }
    @media only screen and (max-width: 640px) {
        background-size: auto 100%;
    }
    @media only screen and (max-width: 600px) {
        padding: 3rem 0;
        h1 {
            max-width: 420px;
            font-size: 2rem;
            line-height: 1.25;
            font-weight: 400;
        }
        p {
            max-width: 420px;
            font-size: 1rem;
            font-weight: 400;
        }
    }
}

#HomeAdminSection {
    padding: 2.5rem 0;
    h1 {
        color: var(--secondary-color);
        font-size: 2.5rem;
        line-height: 1.25;
        font-weight: 400;
    }
    p {
        font-size: 1.2rem;
        font-weight: 400;
    }
    .call-out-box {
        color: var(--secondary-color);
        background-color: #fff;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
        li {
            font-size: 1.2rem;
        }
    }
    .section-img {
        height: 100%;
        background: transparent url(./speducator-child.jpg) no-repeat
            center top;
        background-size: 100% auto;
        @media only screen and (max-width: 767px) {
            min-height: 480px;
        }
    }
}

#PaymentBoxes {
    padding: 2.5rem 0;
    h1 {
        color: var(--secondary-color);
        font-size: 2.5rem;
        line-height: 1.25;
        font-weight: 400;
    }
    p {
        font-size: 1.2rem;
        font-weight: 400;
    }
    .call-out-box {
        color: var(--secondary-color);
        background-color: #fff;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
        li {
            font-size: 1.2rem;
        }
    }
    .call-out-box__image:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
    }
    .section-img {
        height: 100%;
        background: transparent url(./speducator-child.jpg) no-repeat
        center top;
        background-size: 100% auto;
        @media only screen and (max-width: 767px) {
            min-height: 480px;
        }
    }
}

#HomeSpedSection {
    padding: 2.5rem 0;
    h1 {
        color: var(--secondary-color);
        font-size: 2.5rem;
        line-height: 1.25;
        font-weight: 400;
    }
    p {
        font-size: 1.2rem;
        font-weight: 400;
    }
    .call-out-box {
        color: var(--secondary-color);
        background-color: #fff;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
        li {
            font-size: 1.2rem;
        }
    }
    .section-img {
        height: 100%;
        background: transparent url(./happy-child.jpg) no-repeat center
            center;
        background-size: 100% auto;
        @media only screen and (max-width: 767px) {
            min-height: 480px;
        }
    }
}

#HomeMessageSection {
    padding: 5rem 0;
    color: #fff;
    background: #632991 url(./bg-ceo-message.jpg) no-repeat left center;
    background-size: auto 100%;
    h1 {
        text-align: right;
        max-width: 600px;
        margin-left: auto;
        font-size: 2.5rem;
        line-height: 1.25;
        font-weight: 400;
    }
    p {
        text-align: right;
        font-size: 1.2rem;
        font-weight: 400;
        .pipe {
            vertical-align: top;
            display: inline-block;
            padding: 5px 4px 0 4px;
            font-size: 1rem;
            line-height: 1;
            color: rgba(255, 255, 255, 0.4);
        }
    }
    @media only screen and (max-width: 1180px) {
        h1 {
            font-size: 2rem;
            max-width: 540px;
        }
    }
    @media only screen and (max-width: 990px) {
        background: #632991 url(./bg-ceo-message-sm.jpg) no-repeat left
            center;
        background-size: auto 100%;
        h1 {
            max-width: 500;
            margin-left: auto;
        }
    }
    @media only screen and (max-width: 767px) {
        h1 {
            margin-right: 0.5rem;
            font-size: 1.5rem;
            max-width: 420px;
        }
    }

    @media only screen and (max-width: 600px) {
        h1 {
            font-size: 1.33rem;
            max-width: 60%;
        }
        p {
            font-size: 0.875rem;
        }
    }
}

#HomeScholarSection {
    padding: 2.5rem 0;
    .scholarship-info-box {
        padding: 1.5rem;
        background-color: var(--secondary-color);
        color: rgba(255, 255, 255, 0.75);
        border-radius: 0.5rem;
        display: flex;
        div:not(.spacer) {
            flex-basis: 2rem;
            p {
                max-width: 420px;
            }
        }
        .spacer {
            flex-basis: 3rem;
            height: 2rem;
        }
        .general {
            flex-grow: 1;
        }
        .spedx {
            flex-grow: 1;
        }
        h3,
        strong {
            color: #fff;
        }
    }
}

#HomeNewsSection {
    background: #fff;
    padding: 2rem 0;
    hr {
        margin: 0;
        padding: 0;
    }
    h2 {
        margin: 0;
        padding: 0.25rem 0 0.375rem 0;
        color: var(--primary-color);
        font-weight: 600;
        font-size: 2rem;
    }
    .article-preview {
        margin-top: 1.5rem;
        &:first-of-type {
            margin-top: -0.125rem;
        }
        img {
            width: 40%;
            float: right;
            margin: 0 0 1rem 1rem;
        }
        h3 {
            color: var(--secondary-color);
            font-size: 1.225rem;
            line-height: 1.2;
            font-weight: 600;
            margin: 0 0 0.5rem 0;
        }
        p {
            span {
                color: var(--primary-color);
            }
        }
        &:hover {
            cursor: pointer;
            h3 {
                color: var(--secondary-color-hover);
                text-decoration: underline;
            }
            p {
                span {
                    color: var(--primary-color-hover);
                }
            }
        }
    }
    .main-article {
        img {
            margin-bottom: 0.5rem;
        }
        h3 {
            font-size: 2rem;
            font-family: "Ovo", sans-serif;
            font-weight: normal;
            line-height: 1.2;
            margin: 0 0 0.5rem 0;
            color: var(--secondary-color);
        }
        p {
            span {
                color: var(--primary-color);
            }
        }
    }
    &:hover {
        cursor: pointer;
        h3 {
            color: var(--secondary-color-hover);
        }
        p {
            span {
                color: var(--primary-color-hover);
            }
        }
    }
}

#HomePartnersSection{
    background: #fff;
    padding: 2rem 0;
    .image-slider-container {
        position: relative;
        width: 100%;
        overflow-x: hidden;
    }

    .image-slider {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }

    .image-slider img {
        display: block;
        width: 300px;
        height: 200px;
        object-fit: contain;
        cursor: pointer;
        //border-right: 3px solid darkblue;
        padding-right: 10px;
        border: 3px solid;
    }

    @media (max-width: 768px) {
        .image-slider img {
            max-height: 150px;
        }
    }
}




